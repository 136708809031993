import React from 'react'
import './Header.css'
import logo from "../../assets/logo.png"
import $ from 'jquery'

export const Header = () => {

    const hideMenuHandler = () => {
        $('.bar-1').removeClass('bar-1-animation');
        $('.bar-2').removeClass('bar-2-animation');
        $('.bar-3').removeClass('bar-3-animation');
        $('.nav-menu').removeClass('show-menu')
    }

    const showMenuHandler = () =>{
        $('.bar-1').toggleClass('bar-1-animation');
        $('.bar-2').toggleClass('bar-2-animation');
        $('.bar-3').toggleClass('bar-3-animation');
        $('.nav-menu').toggleClass('show-menu')
    }

  return (
    <div className="header">
        <div className="logo">
        <img src={logo} alt="" width="46px"/> 
        </div>
        <div className="nav-button" onClick={showMenuHandler}>
            <span className="bar-1"></span>
            <span className="bar-2"></span>
            <span className="bar-3"></span>
        </div>
        <div className="nav-menu">
            <ul>
                
                <li><a href="#o" onClick={hideMenuHandler}>O mě</a></li>
                <li><a href="#kontakt" onClick={hideMenuHandler}>Kontakt</a></li>
                <li><a href="#faq" onClick={hideMenuHandler}>Faq</a></li>
            </ul>
        </div>
    </div>
  )
}
