import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './Team.css'
import NFT1 from '../../assets/logo.png'
import FB from '../../assets/fb.png'
import IG from '../../assets/ig.png'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger"

export const Team = () => {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        if(window.screen.width>1199){
          gsap.from(".team__member",{
            x:"-200px",
            filter: "blur(5px)",
            stagger:0.5,
            opacity:"0",
            duration:0.5,
            scrollTrigger:{
              trigger:".team__member",
              // scrub:"0.25",
              // end:"+=600px"
              // start:"top"
            },
          })
      
          gsap.from(".team__member h3",{
              x:"-200px",
              filter: "blur(5px)",
              stagger:0.5,
              opacity:"0",
              duration:1,
              scrollTrigger:{
                trigger:".team__member",
                // scrub:"0.25",
                // end:"+=600px"
                // start:"top"
              },
            })
      
            gsap.from(".team__member p",{
              x:"-100px",
              filter: "blur(5px)",
              stagger:0.5,
              opacity:"0",
              duration:1.5,
              scrollTrigger:{
                trigger:".team__member",
                // scrub:"0.25",
                // end:"+=600px"
                // start:"top"
              },
            })
        }

        if(window.screen.width<992){
          gsap.from(".team__member",{
            x:"-200px",
            filter: "blur(5px)",
            stagger:1,
            opacity:"0",
            duration:0.5,
            scrollTrigger:{
              trigger:".team__member",
              // scrub:"0.25",
              // end:"+=600px"
              // start:"top"
            },
          })
      
          gsap.from(".team__member h3",{
              x:"-200px",
              filter: "blur(5px)",
              stagger:1,
              opacity:"0",
              duration:1,
              scrollTrigger:{
                trigger:".team__member",
                // scrub:"0.25",
                // end:"+=600px"
                // start:"top"
              },
            })
      
            gsap.from(".team__member p",{
              x:"-100px",
              filter: "blur(5px)",
              stagger:1,
              opacity:"0",
              duration:1.5,
              scrollTrigger:{
                trigger:".team__member",
                // scrub:"0.25",
                // end:"+=600px"
                // start:"top"
              },
            })
        }
    
    },[])

  return (
    <div className="team pd-b-100 pd-t-80" id="kontakt">
        <Container>
            <Row>
                <Col md={12}>
                    <h2>Kontakt</h2>
                </Col>

                <Col md={4}>
                    <div className="team__member">
                        <img src={NFT1} alt="" />
                        <div className="member__details">
                        <h3>Vladimíra Liptáková</h3>
                        <p>+420734 801 714</p>
                        <p>vlady.lip@gmail.com</p>
                        </div>
                        
                    </div>
                   <center><a href="https://www.facebook.com/equitouch.liptakova"><img src={FB} alt="" width={40}/></a><a href="https://www.instagram.com/equi_touch/">   <img src={IG} alt="" width={40}/></a></center> 
                </Col>
            </Row>
        </Container>
    </div>
  )
}
