import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Hero.css";
// import Minus from "../../assets/images/minus.svg";
// import Plus from "../../assets/images/plus.svg";
import $ from "jquery";
// import Modal from "@mui/material/Modal";
import BgVideo from '../../assets/bg_video.mp4';
import NFT4 from "../../assets/logo.png"
import gsap from "gsap"

export const Hero = () => {
  // const [open, setOpen] = useState(false);
  const [daysTimer , setDays] = useState("00");
  const [hoursTimer, setHours] = useState("00");
  const [minutesTimer, setMinutes] = useState("00");
  const [secondsTimer, setSeconds] = useState("00");

  let interval = useRef();

  const startTimer = ()=>{
    const countDownDate = new Date('Jun 10, 2022 00:00:00').getTime();
    interval = setInterval(()=>{
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(distance % (1000 * 60 * 60 * 24)/ (1000 * 60 * 60))
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60 )) / 1000)

      if(distance < 0){
        clearInterval(interval.current);
      } else{
        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
      }
    },1000)
  }

  useEffect(()=>{
    startTimer();
    return ()=>{
      clearInterval(interval.current);
    }
  })

  useEffect(()=>{
    if(window.screen.width>1199){
      gsap.from(".hero__title",{
        x:"-300px",
        filter:"blur(4px)",
        duration:1,
        opacity:0
      })
  
      gsap.from(".hero__content h3",{
        x:"100px",
        filter:"blur(5px)",
        duration:1,
        opacity:0
      })
  
      gsap.from(".hero__content img",{
        scale:0,
        filter:"blur(5px)",
        duration:1,
        opacity:0
      })
  
      gsap.from(".timer__box",{
        y:"100px",
        filter:"blur(5px)",
        duration:1,
        opacity:0,
        stagger:0.5
      })
    }

    else if(window.screen.width<992){
      gsap.from(".hero__title",{
        x:"-300px",
        filter:"blur(5px)",
        duration:1,
        opacity:0
      })
  
      gsap.from(".hero__content h3",{
        x:"20px",
        filter:"blur(5px)",
        duration:1,
        opacity:0
      })
  
      gsap.from(".hero__content img",{
        scale:0,
        filter:"blur(5px)",
        duration:1,
        opacity:0
      })
  
      gsap.from(".timer__box",{
        y:"100px",
        filter:"blur(5px)",
        duration:1,
        opacity:0,
        stagger:0.5
      })
    }
   
  },[])

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <div className="hero pd-t-80 pd-b-100">
      <div className="video__container">
      <video autoplay="true" loop muted>
        <source src={BgVideo} type="video/mp4" />
      </video>
      </div>
      <div className="hero__bg__overlay"></div>
      <Container>
        <Row>
          <Col md={12}>
            <div className="hero__content">
              <h2 className="hero__title">Equi touch</h2>
              { /*<h3>terapie pro koně</h3>*/}
             <br/>
             <br/>
             <br/>
              { /*<Slider img1={NFT2} img2={NFT3} img3={NFT4}/>*/}
             
              {/*<div className="timer">
                <Row>
                  <Col md={3}>
                    <div className="timer__box">
                      <h2>{daysTimer}</h2>
                      <h4>Days</h4>
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="timer__box">
                      <h2>{hoursTimer}</h2>
                      <h4>Hours</h4>
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="timer__box">
                      <h2>{minutesTimer}</h2>
                      <h4>Minutes</h4>
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="timer__box">
                      <h2>{secondsTimer}</h2>
                      <h4>Seconds</h4>
                    </div>
                  </Col>
                </Row>
              </div>*/}
              {/*<a href="https://instagram.com/equi_touch" className="whitepaper">
                Instagram
              </a>*/}
              {/*<a href="" className="whitepaper mint">
                Youtube?
            </a>*/}
            </div>
          </Col>
        </Row>
      </Container>
      {/* <Modal open={open} onClose={handleClose}>
        <div className="mint__modal">
          <h3>Mint NFT</h3>
          <p>10,000 NFTs</p>
          <div className="balance modal__flex">
            <h5>Balance</h5>
          </div>
          <hr />
          <div className="amount modal__flex">
            <h5>Amount</h5>
            <div className="amount__buttons">
              <img src={Minus} alt="" />
              <h5>2</h5>
              <img src={Plus} alt="" />
            </div>
            <a className="whitepaper">Max</a>
          </div>
          <hr />
          <div className="total modal__flex">
            <h5>Total</h5>
            <p>0.08 ETH</p>
          </div>
          <hr />
          <button disabled="true">Unavailable</button>
          <p>Make sure your Metamask Wallet is Connected</p>
        </div>
      </Modal> */}
    </div>
  );
};
