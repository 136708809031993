import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { Header } from './components/header/Header';
import { Hero } from './components/hero/Hero';
import { About } from './components/about/About';
import { Team } from './components/team/Team';
import { Faq } from './components/faq/Faq';
import { BrowserRouter , Routes , Route } from 'react-router-dom';
import React from 'react';


function App() {
  return (
    <BrowserRouter>
    <div className="app">
      
      <Header/>
      <Routes>
        <Route path="/" element={<React.Fragment>
          <Hero/>
      <About/>
      <Team/>
      <Faq/>
        </React.Fragment>} />
      </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;
