import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./About.css";
import logo from "../../assets/logo.png";
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger"

export const About = () => {

  useEffect(()=>{
    gsap.registerPlugin(ScrollTrigger);
    if(window.screen.width>=1199){
      gsap.from(".about__para",{
        x:"-200px",
        filter: "blur(5px)",
        opacity:"0",
        scrollTrigger:{
          trigger:".about__para",
          scrub:"0.18",
          end:"+=600px"
          // start:"top"
        },
      })
  
      gsap.from(".about__para p",{
        x:"200px",
        filter: "blur(5px)",
        stagger:1,
        opacity:"0",
        duration:5,
        scrollTrigger:{
          trigger:".about__para p",
          scrub:"0.18",
          // start:"top center",
          end:"+=700px"
        },
      })
  
      gsap.from(".about__img img",{
        x:"100px",
        filter: "blur(5px)",
        opacity:"0",
        // duration:"1",
        scrollTrigger:{
          trigger:".about__para",
          scrub:0.18,
          // start:"top center",
          end:"+=500px"
        },
      })
    }

    else if(window.screen.width<=992){
      gsap.from(".about__para",{
        x:"-200px",
        filter: "blur(5px)",
        opacity:"0",
        scrollTrigger:{
          trigger:".about__para",
          // scrub:"0.18",
          // end:"+=600px"
          // start:"top"
        },
      })
  
      gsap.from(".about__para p",{
        x:"50px",
        filter: "blur(5px)",
        stagger:0.5,
        opacity:"0",
        duration:1,
        scrollTrigger:{
          trigger:".about__para p",
          // scrub:"0.18",
          // start:"top center",
          // end:"+=700px"
        },
      })
  
      gsap.from(".about__img img",{
        x:"20px",
        filter: "blur(5px)",
        opacity:"0",
        // duration:"1",
        scrollTrigger:{
          trigger:".about__img img",
          // scrub:true,
          // start:"top center",
          // end:"+=300px"
        },
      })
    }
    
  },[])

  return (
    <div className="about pd-b-100 pd-t-80" id="o">
      <Container>
        <Row>
        <Col md={6} className="mobile-only">
        <h2>TERAPIE PRO KONĚ</h2>
            <div className="about__img">
                  <img src={logo} alt="" />            
            </div>
          </Col>
          <Col md={6}>
            <div className="about__content">
            <h2 className="desktop-only">TERAPIE PRO KONĚ</h2>
              <div className="about__para">
              <p>
              Koňské masáže jsou prospěšné pro koně všech věkových skupin , sportovní i rekreační. Ať už drezura či skoky, dostihy nebo westernové odvětví, stojí za to na chvíli ocenit,co pro nás koně dělají. Masáže mohou pomoci koni předcházet zraněním a urychlení zotavení po úrazu. Při správném provedení mohou koňské masáže přinést koni relaxaci a zlepšit jeho celkovou pohodu.
              </p>
              <p>
              Masáže mohou být aplikovány před zátěží, jako před závody nebo jízdou v terénu, aby se koni uvolnila svalová napětí a zlepšila průtok krve a lymfy. 
              </p>
              <p>
              Masáže mohou také pomoci zlepšit celkovou pohodu koně tím, že pomáhají snížit stres a úzkost. Při masáži se uvolňují endorfiny, které přinášejí koni pocit pohody a uvolnění. 
              </p>
              </div>
            </div>
          </Col>
          <Col md={6} className="desktop-only">
            <div className="about__img">
                  <img src={logo} alt="" />            
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
