import React from "react";
import "./Faq.css";
import { Col, Container, Row } from "react-bootstrap";
import Plus from "../../assets/images/plus.svg";
import $ from "jquery";

export const Faq = () => {
  const showAnswerHandler = (e) => {
    const id = e.target.id;
    const answers = [...$(".answer")];
    const answer = answers.find((el) => el.id === id);
    $(answer).toggleClass("show-answer");
    const images = [...$(".question img")];
    const img = images.find((el) => el.id === id);
    $(img).toggleClass("rotate-icon");
    const questions = [...$(".question")];
    const question = questions.find((el) => el.id === id);
    $(question).toggleClass("question-bg");
    const buttons = [...$(".question button")];
    const button = buttons.find((el) => el.id === id);
    $(button).toggleClass("button-color");
  };

  return (
    <div className="faq pd-b-100 pd-t-80" id="faq">
      <Container>
        <Row>
          <Col md={12}>
            <h2>FAQ - často kladené otázky</h2>
          </Col>
          <Col md={12}>
            <div className="question" id="1">
              <h3 onClick={showAnswerHandler} id="1">
                <img src={Plus} alt="" id="1" />
                <button id="1">
                 Kde působím?
                </button>
              </h3>

              <div className="answer" id="1">
                <p>
                 Působím převážně v oblasti Západních Čech, po domluvě není vyloučeno dojet kamkoliv v Čechách.
                </p>
              </div>
            </div>
          </Col>

          <Col md={12}>
            <div className="question" id="2">
              <h3 onClick={showAnswerHandler} id="2">
                <img src={Plus} alt="" id="2" />
                <button id="2">
                Kolik to stojí peněz?
                </button>
              </h3>

              <div className="answer" id="2">
                <p>
                  Cena ošetření jednoho koně je 800 Kč  + cestovní náklady. Pro stálé a pravidelné klienty je věrnostní program. 
                </p>
              </div>
            </div>
          </Col>

           <Col md={12}>
            <div className="question" id="3">
              <h3 onClick={showAnswerHandler} id="3">
                <img src={Plus} alt="" id="3" />
                <button id="3">Jak dlouho masáž trvá? </button>
              </h3>

              <div className="answer" id="3">
                <p>Přibližně 1 hodinu</p>
              </div>
            </div>
          </Col>

          <Col md={12}>
            <div className="question" id="4">
              <h3 onClick={showAnswerHandler} id="4">
                <img src={Plus} alt="" id="4" />
                <button id="4">Je potřeba koně na masáž připravit?</button>
              </h3>

              <div className="answer" id="4">
                <p>
                Ano, kůň by měl být suchý a čistý. Spousta masážních technik zahrnuje jemné doteky, které přes vrstvu bláta nemají žádoucí efekt. Při terapii pohybuji končetinami a nohy mokré, obalené bahnem kloužou a těžko se s nimi manipuluje. Ani mokrý kůň není ideální, mokrá srst zvyšuje tření. No a v neposlední řadě je dobré koně před masáží ošetřit repelentem proti hmyzu, aby si mohl relax nerušeně užít. 
                </p>
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="question" id="5">
              <h3 onClick={showAnswerHandler} id="5">
                <img src={Plus} alt="" id="5" />
                <button id="5">Co s koněm po masáži?</button>
              </h3>

              <div className="answer" id="5">
                <p>
                Po masáži je ideální klid a odpočinek nejlépe ve výběhu nebo klidová procházka.
                </p>
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="question" id="6">
              <h3 onClick={showAnswerHandler} id="6">
                <img src={Plus} alt="" id="6" />
                <button id="6">Jak často koně masírovat? </button>
              </h3>

              <div className="answer" id="6">
                <p>
                Odpověď je vždy individuální vůči koni a jeho stavu.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
